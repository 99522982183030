export const setCssVariables = (vars: Record<string, string>) => {
  Object.entries(vars).forEach(([key, val]) => {
    document.documentElement.style.setProperty(key, val);
  })
};

export const addCSSClassFromObject = (className: string, cssObject: Record<string, string>) => {
  const style = document.createElement('style');

  let cssRule = `.${className} {`;
  for (const property in cssObject) {
    if (Object.prototype.hasOwnProperty.call(cssObject, property)) {
      cssRule += `${property}: ${cssObject[property]};`;
    }
  }
  cssRule += '}';

  style.textContent = cssRule;
  document.head.appendChild(style);

  return className;
}

