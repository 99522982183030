import {FunctionalComponent} from 'preact';
import ReportBugModal from "../../components/standalone/ReportBugModal";

const ReportBugRoute: FunctionalComponent = (props) => {

  return (
      <ReportBugModal />
  );
};

export default ReportBugRoute;

