import axios from 'axios'
import {safeLocalStorage, safeSessionStorage} from '../utils/storage';
import {CurrentUserResponse, UserProgressResponse} from "../interfaces/sdk";
import {FormattedUserProgress, formatUserProgress} from "../utils/userProgress";
import { pageInfo } from '../global';

axios.defaults.baseURL = process.env.PREACT_APP_API_BASE_URL

export const tokenTypes = ['development', 'production', 'preview'] as const;
export type TokenType = typeof tokenTypes[number] | string

export const authAxios = axios.create()

export const fetchCurrentUser = async (): Promise<CurrentUserResponse> => {
  const {data} = await authAxios.get(`/sdk/current-user`, { params: { page_info_url: pageInfo.url }})
  return data
}

export const fetchUserProgress = async (): Promise<FormattedUserProgress> => {
  const {data} = await authAxios.get<UserProgressResponse>(`/sdk/user-progress`, { params: { page_info_url: pageInfo.url }})

  return formatUserProgress(data.userProgress)
}

export const setAuthAxiosHeaders = (token: string, appKeyId: string) => {
  authAxios.defaults.headers.Authorization = `Bearer ${token}`
  authAxios.defaults.headers['gl-app-key-id'] = appKeyId
}

export const clearAuthAxiosAuthorization = () => {
  authAxios.defaults.headers.Authorization = ''
}

type LocalUser = {
  accessToken: string,
  refreshToken: string,
  appSlug: string,
  appKeyId: string
}

export const saveUserLocally = (isSessionStorage: boolean, data: LocalUser) => {
  if (isSessionStorage) {
    safeSessionStorage.setItem(`${data.appSlug}_user`, JSON.stringify(data))
  } else {
    safeLocalStorage.setItem(`${data.appSlug}_user`, JSON.stringify(data))
  }
}

export const getLocalUser = (isSessionStorage: boolean, appSlug: string) => {
  const key = `${appSlug}_user`
  if (isSessionStorage) {
    //Remove user from localstorage if it already exists, since using session storage
    if (safeLocalStorage.getItem(key)) {
      safeLocalStorage.removeItem(key)
    }
    return safeSessionStorage.getItem(key)
  }
  return safeLocalStorage.getItem(key)
}

export const removeLocalUser = (isSessionStorage: boolean, appSlug: string) => {
  if (isSessionStorage) {
    safeSessionStorage.removeItem(`${appSlug}_user`)
  } else {
    safeLocalStorage.removeItem(`${appSlug}_user`)
  }
}

export const setAuthAxiosLocaleHeader = (lang: string, region: string) => {
  authAxios.defaults.headers.lang = lang
  authAxios.defaults.headers.region = region
}
