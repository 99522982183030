import styles from './ReportBugModal.styles.scss'
import Modal from 'react-bootstrap/Modal';
import Button from "../Button";
import {useNavigate} from "react-router-dom";
import * as standaloneRoutes from "../../appRouter/routesPaths/standalone";
import {useSettingsContext} from "../../contexts/SettingsContext";
import BugIcon from "../icons/BugIcon";
import {useEffect, useRef} from "preact/hooks";
import {reportBug} from "../../services/bugReport";
import {toast} from "react-hot-toast";
import useTranslation from "../../hooks/useTranslation";
import {useState} from "preact/compat";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

function ReportBugModal() {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {appSlug, context} = useSettingsContext()
  const reporterInputRef = useRef<HTMLInputElement>(null);
  const descriptionInputRef = useRef<HTMLTextAreaElement>(null);
  const [submitting, setSubmitting] = useState(false);
  const {executeRecaptcha} = useGoogleReCaptcha()

  useEffect(() => {
    document.body.classList.add('show-recaptcha')
    return () => {
      document.body.classList.remove('show-recaptcha')
    }
  }, [])

  useEffect(() => {
    if (reporterInputRef.current) {
      reporterInputRef.current.focus();
    }
  }, []);

  const handleClose = () => {
    navigate(standaloneRoutes.home({appSlug, context}))
  };

  const onSubmit = async (e: any) => {
    try {
      setSubmitting(true)
      e.preventDefault()
      const reporter = reporterInputRef.current?.value || ''
      const description = descriptionInputRef.current?.value || ''
      const recaptchaToken = executeRecaptcha && await executeRecaptcha('bugReport')
      await reportBug({reporter, description, recaptchaToken})
      navigate('..')
      toast.success(t('notifications.bugReported'))
    } catch (e) {
      toast.error(t('errors.generic'))
      console.error(e)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <Modal show={true} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title className={styles.ReportBugModal}>
            <BugIcon /> {t('bugReport.title')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit}>
            <div className="mb-4">
              <label htmlFor="reporter-name" className="form-label">{t('bugReport.name')}</label>
              <input required type="text" className="form-control" id="reporter-name" ref={reporterInputRef} />
            </div>
            <div className="mb-4">
              <label htmlFor="bug-description" className="form-label">{t('bugReport.description')}</label>
              <textarea required type="text" className="form-control" id="bug-description" ref={descriptionInputRef} />
            </div>
            <Button type="submit" disabled={submitting}>
              {t('bugReport.submit')}
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReportBugModal;
