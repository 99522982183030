import {FunctionalComponent, h} from 'preact';
import {Children, createContext} from "preact/compat";
import {useContext, useEffect, useMemo} from "preact/hooks";
import {useSettingsContext} from "../SettingsContext";
import {setCustomColors} from "../../utils/styles";
import {addCSSClassFromObject, setCssVariables} from "./utils";
// @ts-ignore
import {Helmet} from "react-helmet";
import {parseToRgba, rgba} from "color2k";

type ThemeContextValue = {
  btnPrimary: string;
  headerBgColor: string;
}

const ThemeContext = createContext<ThemeContextValue | undefined>(undefined)

const ThemeProvider: FunctionalComponent = ({children}) => {
  const {application} = useSettingsContext()

  const isGoogleFont = useMemo(() => {
    return application.theme.fonts?.url?.includes('https://fonts.googleapis.com')
  }, [application.theme.fonts?.url])

  useEffect(() => {
    const headerFonts = [application.theme.fonts.heading, 'DM Sans', 'Arial', 'sans-serif'].filter(Boolean)
    const bodyFonts = [application.theme.fonts.body, 'DM Sans', 'Arial', 'sans-serif'].filter(Boolean)
    const btnPrimary = application.theme.colors.btn_primary || '#1E293B'
    const [r,g,b] = parseToRgba(btnPrimary)
    const lightBtnPrimary = rgba(r, g, b, 0.1)

    setCssVariables({
      '--font-family-header-key': headerFonts.join(', '),
      '--font-family-body-key': bodyFonts.join(', '),
      '--font-weight-base-key': `${application.theme.font_weights.body}` || '400',
      '--font-weight-header-key': `${application.theme.font_weights.header}` || '700',
    })
    addCSSClassFromObject('btn-outline-primary', {
      '--bs-btn-color': btnPrimary,
      '--bs-btn-border-color': btnPrimary,
      '--bs-btn-hover-color': '#fff',
      '--bs-btn-hover-bg': btnPrimary,
      '--bs-btn-hover-border-color': btnPrimary,
      '--bs-btn-focus-shadow-rgb': '13, 110, 253',
      '--bs-btn-active-color': '#fff',
      '--bs-btn-active-bg': btnPrimary,
      '--bs-btn-active-border-color': btnPrimary,
      '--bs-btn-active-shadow': 'inset 0 3px 5px rgba(0, 0, 0, 0.125)',
      '--bs-btn-disabled-color': btnPrimary,
      '--bs-btn-disabled-bg': 'transparent',
      '--bs-btn-disabled-border-color': btnPrimary,
      '--bs-gradient': 'none',
    })
    addCSSClassFromObject('dropdown-menu', {
      '--bs-dropdown-link-color': '#212529',
      '--bs-dropdown-link-hover-color': '#1e2125',
      '--bs-dropdown-link-hover-bg': 'transparent',
      '--bs-dropdown-link-active-color': '#0F172A',
      '--bs-dropdown-link-active-bg': lightBtnPrimary,
      '--bs-dropdown-link-disabled-color': '#adb5bd',
    })
    setCustomColors(
        btnPrimary,
        application.theme.colors.header_bg,
        application.theme.colors.text_btn_primary,
        application.theme.colors.header_font
    )
  }, [application])

  return (
      <ThemeContext.Provider
          value={{
            btnPrimary: application.theme.colors.btn_primary,
            headerBgColor: application.theme.colors.header_bg,
          }}
      >
        {application.theme.fonts?.url && (
            <>
              <Helmet>
                <link href={application.theme.fonts?.url} rel="stylesheet" />
              </Helmet>
            </>
        )}

        {Children.only(children)}
      </ThemeContext.Provider>
  );
};

export default ThemeProvider;

export const useThemeContext = () => {
  const context = useContext(ThemeContext)
  if (context === undefined) {
    throw new Error('useThemeContext must be used within ThemeProvider')
  }
  return context
}

