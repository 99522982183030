import Dropdown from 'react-bootstrap/Dropdown';
import useTranslation from "../../hooks/useTranslation";
import {useEffect, useMemo} from "preact/hooks";
import useInvalidateClaimableRewards from "../../hooks/invalidateQueries/useInvalidateClaimableRewards";

const choices = [
  {value: 'fr-caqc', label: 'Québec - Français'},
  {value: 'en-caqc', label: 'Quebec - English'},
  {value: 'fr-caon', label: 'Ontario - Français'},
  {value: 'en-caon', label: 'Ontario - English'},
]

function SelectLocaleDropdown() {
  const invalidateClaimableRewards = useInvalidateClaimableRewards()
  const {t, setLocale: setLang, locale: lang, region, setRegion} = useTranslation();
  const locale = `${lang}-${region}`;

  const selectedLocale = useMemo(() => {
    return choices.find(item => item.value === locale) || {value: '', label: ''}
  }, [locale])

  const handleSelect = (eventKey: string | null) => {
    const [lang, region] = eventKey?.split('-') || []
    setLang(lang || 'en')
    setRegion(region || '')
  };

  useEffect(() => {
    void invalidateClaimableRewards()
  }, [region, lang, invalidateClaimableRewards])

  return (
    <Dropdown onSelect={handleSelect}>
      <Dropdown.Toggle variant="outline-primary" id="locale-selector" className="rounded-5">
        {selectedLocale.label || t('common.selectLocale')}
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-3">
        {choices.map((item) => (
          <Dropdown.Item
            key={item.value}
            eventKey={item.value}
            active={locale === item.value}
            className="bolder rounded-5 py-3 px-4"
          >
            {item.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default SelectLocaleDropdown;
